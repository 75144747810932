import Layout from "./common/layout"
import Head from "./common/Head"
import OpolloDetail from "./product/Integrated/OpolloDetail"
import { useFormatter } from "helpers/i18n"
import underMaintenance from "images/under_maintenance.svg"

const ProductOpollo = () => {
  const { __, currentLocale } = useFormatter()

  return (
    <>
      {/* <Head
        showCaptcha={true}
        title={
          currentLocale === "en"
            ? "Opollo - Omni-Channel Order Management System"
            : "Opollo - Hệ thống quản lý bán hàng đa kênh"
        }
        description={
          currentLocale === "en"
            ? "Your New Bridge to the E-Commerce Promised Land"
            : "Your New Bridge to the E-Commerce Promised Land"
        }
        image={
          "https://s3.ap-southeast-1.amazonaws.com/public.onpoint.vn/product-2-meta.png"
        }
      />
      <Layout>
        <OpolloDetail />
      </Layout> */}

      <Head
        showCaptcha={true}
        title={
          currentLocale === "en" ? "Under Maintenance" : "Hệ Thống Đang Bảo Trì"
        }
      />
      <Layout>
        <div
          style={{
            textAlign: "center",
            marginTop: "50px",
            justifyContent: "center",
            color: "#2d5997",
          }}
        >
          <img
            src={underMaintenance}
            alt="Under Maintenance"
            style={{ width: "70vh" }}
          />
          <h1>
            {__({
              defaultMessage: "We are Under Maintenance.",
            })}
          </h1>
          <h4 className="mb-4">
            {__({
              defaultMessage: "Will be Back Soon!",
            })}
          </h4>
        </div>
      </Layout>
    </>
  )
}

export default ProductOpollo
